import { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';

// @mui
import { Link as RouterLink } from 'react-router-dom';
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  Box,
  Button,
  Link,
  InputAdornment,
  Alert,
} from '@mui/material';
// google
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import { BRAND, DISABLE_LOGIN } from '../../config-global';
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
import isLocalhost from '../../utils/functions';
import useAnalytics from '../../hooks/useAnalytics';
import { GA } from '../../constants/ga-events';
import LoadingScreen from '../../components/loading-screen';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { trackEvent } = useAnalytics();
  const { googleLogin, login } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  const handleGoogleLogin = async (tokenResponse) => {
    try {
      setLoading(true);
      await googleLogin(tokenResponse?.credential);
    } catch (error) {
      enqueueSnackbar('Unable to login!', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <GoogleOAuthProvider clientId="519258092292-cou9nra3rth4if4gglc42b6v0m49eg53.apps.googleusercontent.com">
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Typography variant="body2" mb={8} textAlign="left">
            Streamline Your Instagram Automation with One Click.
          </Typography>

          {/*  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField size="small" name="email" label="Email address" />

              <RHFTextField
                name="password"
                label="Password"
                size="small"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                fullWidth
                color="inherit"
                size="medium"
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'text.primary',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'text.primary',
                    color: (theme) =>
                      theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
                  },
                }}
              >
                Login
              </LoadingButton>
            </Stack>
          </FormProvider> */}

          {/*  <Typography variant="body1" fontSize={10} sx={{ mb: 3, mt: 3 }}>
            OR
          </Typography> */}
          <GoogleLogin
            useOneTap
            onSuccess={handleGoogleLogin}
            onError={() => console.log('Login failed')}
          />
          <Typography variant="body1" fontSize={12} sx={{ mt: 4 }}>
            By signing up, you agree to {BRAND.name}{' '}
            <Link
              component={RouterLink}
              to={PATH_PAGE.tos}
              color="#0084ff"
              variant="body1"
              fontSize={12}
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              component={RouterLink}
              to={PATH_PAGE.privacy}
              color="#0084ff"
              variant="body1"
              fontSize={12}
            >
              Privacy Policy
            </Link>
          </Typography>
          <div>
            <Typography variant="body1" fontSize={10} sx={{ mt: 16 }}>
              This application uses{' '}
              <Link
                href="https://developers.facebook.com/docs/instagram-platform/instagram-api-with-instagram-login"
                color="#0084ff"
                variant="body1"
                fontSize={10}
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram API with Instagram Login
              </Link>
              , developed and maintained by Meta, to ensure secure and seamless access to your
              account.
            </Typography>
            <Iconify icon="logos:meta" width={60} />
          </div>
        </Box>
      )}
    </GoogleOAuthProvider>
  );
}
