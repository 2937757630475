// AUTH
export const INSTAGRAM_CONNECT_API = '/auth/instagram';
export const LOGOUT_API = '/auth/logout';
export const GOOGLELOGIN_API = '/auth/google';
export const LOGIN_API = '/auth/login';

// ENQUIRY
export const POST_ENQIORY_API = '/enquiry/add';

// ACCOUNT
export const MY_ACCOUNT_API = '/account/my-account';
export const POST_MY_ACCOUNT_API = '/account/my-account';
export const POST_COUNTRY_API = '/account/country';
export const GET_DASHBOARD_DATA_API = '/account/dashboard';

// POST
export const GET_POST_LIST_API = '/posts/list';
export const GET_POST_AUTO_DM_ENABLED_STATUS_API = '/posts/';
export const POST_UPLOAD_MEDIA_API = '/posts/upload';

// TEMPLATE
export const GET_TEMPLATE_BY_POST_ID_API = '/template/';
export const POST_TEMPLATE_BY_POST_ID_API = '/template/add';
export const DEL_TEMPLATE_BY_POST_ID_API = '/template/';

// PLANS
export const GET_PRICING_PLANS_API = '/pricing/plans';

// ORDERS
export const GET_CREATE_ORDER_API = '/order/create';
export const GET_CREATE_ORDER_BULK_DM_API = '/order/create-bulk-dm';
export const GET_ORDER_LIST_API = '/order/list';

// RECOMMENDATION
export const START_RECOMMENDATION_API = '/recommendation/start';
export const GET_JOB_STATUS_API = '/recommendation/status';

// ANALYTICS
export const UPDATE_LEAD_CLK_API = '/admin/leads/clk';

// AUTOMATION
export const GET_AUTOMATION_API = '/automation/';
export const POST_AUTOMATION_API = '/automation/add';
